import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Client from './client';
import { ThemeProvider } from './theme/ThemeProvider';
import { MeetingState } from './types';
import JoinView from './views/Join';
import Participant from './views/Participant';
import LandingPage from './views/LandingPage';

const AppContainer = styled.div`
  max-height: -webkit-fill-available;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
`;

type ViewName = 'HOME' | 'JOIN' | 'MODERATOR' | 'PARTICIPANT' | 'SPEAKER';

const INITIAL_MEETING_STATE: MeetingState = {
  queues: [],
  votes: [],
  participants: [],
};
export const MeetingStateContext = React.createContext<MeetingState>(INITIAL_MEETING_STATE);

export const NavigatorContext = React.createContext({
  navigate: (view: ViewName) => {},
});

interface ClientContext {
  client?: Client | null;
  setClient: (client: Client) => void;
}

export const ClientContext = React.createContext<ClientContext>({
  setClient: () => {},
});

const switchView = (view: ViewName) => {
  switch (view) {
    case 'HOME':
      return <LandingPage />;
    case 'PARTICIPANT':
      return <Participant />;
    case 'JOIN':
    default:
      return <JoinView />;
  }
};

const App = () => {
  const [currentView, setCurrentView] = useState<ViewName>('HOME');
  const [client, setClient] = useState<Client | null>(null);
  const [meetingState, setMeetingState] = useState<MeetingState>(INITIAL_MEETING_STATE);

  const setCurrentViewByUrl = useCallback(() => {
    switch (document.location.pathname) {
      case '/':
        setCurrentView('HOME');
        break;
      case '/app':
      default:
        setCurrentView('JOIN');
    }
  }, [setCurrentView]);

  useEffect(() => {
    setCurrentViewByUrl();
    const handlePopState = (event: PopStateEvent) => setCurrentViewByUrl();
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [setCurrentViewByUrl]);

  const setClientAndSubscribeToStateChanges = (client: Client) => {
    setClient(client);
    client.addMeetingStateListener(setMeetingState);
  };

  return (
    <MeetingStateContext.Provider value={meetingState}>
      <ClientContext.Provider
        value={{
          client,
          setClient: (client: Client): void => setClientAndSubscribeToStateChanges(client),
        }}
      >
        <NavigatorContext.Provider value={{ navigate: setCurrentView }}>
          <ThemeProvider>
            <AppContainer className='App'>{switchView(currentView)}</AppContainer>
          </ThemeProvider>
        </NavigatorContext.Provider>
      </ClientContext.Provider>
    </MeetingStateContext.Provider>
  );
};

export default App;
