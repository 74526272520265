import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Popover,
} from '@material-ui/core';
import MoreVerticalIcon from '@material-ui/icons/MoreVert';
import ShareIcon from '@material-ui/icons/ShareOutlined';
import ChatIcon from '@material-ui/icons/Chat';
import ScreenLockPortraitIcon from '@material-ui/icons/ScreenLockPortrait';

import copy from 'clipboard-copy';
import React from 'react';
import Switch from '@material-ui/core/Switch';
import { useNoSleep } from '../hooks';
import styled from 'styled-components';

interface SecondaryActionsProps extends React.HTMLProps<HTMLDivElement> {}

const Container = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;

  // Desktop
  @media (min-width: 480px) {
    top: auto;
    bottom: 16px;
  }
`;

const SecondaryActions: React.FC<SecondaryActionsProps> = (props) => {
  const [noSleepActive, toggleNoSleep] = useNoSleep();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onMoreActionsClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Container>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <List component='nav' aria-label='actions'>
          <ListItem
            button
            onClick={() => {
              copy(window.location.href);
            }}
          >
            <ListItemIcon>
              <ShareIcon />
            </ListItemIcon>
            <ListItemText primary='Copy Shareable Link' />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ScreenLockPortraitIcon />
            </ListItemIcon>
            <ListItemText id='switch-list-label-wifi' primary='No sleeping' />
            <ListItemSecondaryAction>
              <Switch
                edge='end'
                onChange={toggleNoSleep}
                color='primary'
                checked={noSleepActive}
                inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button component='a' href={'mailto:hi@emcee.live?subject=Feedback:'}>
            <ListItemIcon>
              <ChatIcon />
            </ListItemIcon>
            <ListItemText primary='Feedback' />
          </ListItem>
        </List>
        <Divider />
        <List component='nav' aria-label='links'>
          <ListItem button component='a' href='https://elbstack.com/'>
            <ListItemText primary='Built by elbstack 💙' />
          </ListItem>
        </List>
      </Popover>
      <div style={props.style}>
        <IconButton aria-label='more actions' size={'medium'} onClick={onMoreActionsClick}>
          <MoreVerticalIcon />
        </IconButton>
      </div>
    </Container>
  );
};

export default SecondaryActions;
