import {
  Button,
  CircularProgress,
  createStyles,
  Divider,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import QRCode from 'qrcode.react';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import Client from '../client';
import { useNavigator, useSetClient } from '../hooks';
import Colors from '../theme/Colors';
import { isMobile } from '../utils/sizes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      background: `linear-gradient(24deg, ${Colors.blue} 0%, ${Colors.yellow} 100%)`,
    },
    form: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '8px',
      boxShadow: theme.shadows[5],
      padding: '8px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: '300px',
      '& > *': { padding: '8px' },
      '& input[disabled]': { cursor: 'not-allowed' },
    },
    inputs: {
      width: '80%',
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      '//marginBottom': '16px',
      '& > *': { marginBottom: '16px' },
    },
    meeetingInput: {
      position: 'relative',
      '& button': { position: 'absolute', right: '-26px', top: '12px' },
    },
    button: {
      alignSelf: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    qrCode: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: theme.palette.text.secondary,
      '& hr': { marginTop: '8px', marginBottom: '24px', width: '100%' },
      '& canvas': {
        padding: 4,
        backgroundColor: theme.palette.common.white,
      },
    },
    loadingText: {
      color: '#FFFFFF',
    },
  })
);

export const EMCEE_LAST_USERNAME_KEY = 'EMCEE_USERNAME';
export const EMCEE_LAST_MEETING_KEY = 'EMCEE_LAST_MEETING';

interface FormData {
  meeting: string;
  name: string;
}

export default () => {
  const classes = useStyles();
  const navigator = useNavigator();
  const setClient = useSetClient();
  const { control, handleSubmit, errors } = useForm<FormData>();

  // State
  const meetingNameFromUrl = React.useMemo(() => {
    const urlPart = window.location.pathname.substring(1);
    return decodeURI(urlPart);
  }, []);
  const lastMeetingFromStorage = localStorage.getItem(EMCEE_LAST_MEETING_KEY) as string;

  const meetingName = React.useMemo<string>(
    () => (meetingNameFromUrl || lastMeetingFromStorage) ?? '',
    [lastMeetingFromStorage, meetingNameFromUrl]
  );

  const name = React.useMemo<string>(() => localStorage.getItem(EMCEE_LAST_USERNAME_KEY) ?? '', []);
  const [loading, setLoading] = React.useState<boolean>(false);

  const onClickEditMeetingName = React.useCallback(() => {
    localStorage.setItem(EMCEE_LAST_MEETING_KEY, meetingName);
    window.location.href = window.location.origin;
  }, [meetingName]);

  const joinMeeting = React.useCallback(
    async ({ meeting, name }: FormData) => {
      setLoading(true);
      localStorage.setItem(EMCEE_LAST_USERNAME_KEY, name);
      localStorage.setItem(EMCEE_LAST_MEETING_KEY, meeting);
      const client = new Client(name);
      await client.connect(meeting);
      setClient(client);
      setTimeout(() => navigator.navigate('PARTICIPANT'), 2000);
      if (meeting !== meetingNameFromUrl) {
        window.history.pushState({ meeting }, meeting, `/${meeting}`);
      }
    },
    [setClient, meetingNameFromUrl, navigator]
  );

  return (
    <div className={classes.container}>
      {!loading ? (
        <form className={classes.form} onSubmit={handleSubmit(joinMeeting)} noValidate>
          <div className={classes.inputs}>
            <div className={classes.meeetingInput}>
              <Controller
                as={TextField as any}
                name='meeting'
                label='Your Meeting'
                fullWidth
                disabled={!!meetingNameFromUrl}
                control={control}
                error={'meeting' in errors}
                helperText={errors.meeting?.message}
                required={true}
                rules={{
                  required: {
                    value: true,
                    message: 'Please give the meeting a name',
                  },
                }}
                defaultValue={meetingName}
              />

              {meetingNameFromUrl && (
                <Button onClick={onClickEditMeetingName}>
                  <EditIcon />
                </Button>
              )}
            </div>
            <div>
              <Controller
                as={TextField as any}
                name='name'
                label='Your Name'
                fullWidth
                control={control}
                error={'name' in errors}
                helperText={errors.name?.message}
                required={true}
                rules={{
                  required: {
                    value: true,
                    message: 'Who are you?',
                  },
                }}
                defaultValue={name}
              />
            </div>
          </div>
          <div className={classes.button}>
            <Button
              color='primary'
              variant='contained'
              endIcon={<SendIcon />}
              type='submit'
              fullWidth
            >
              Join
            </Button>
          </div>
          {!isMobile() && (
            <div className={classes.qrCode}>
              <Divider light variant='fullWidth' />
              <QRCode value={`${window.location.origin}/${meetingName}`} />
              <Typography variant='caption'>
                Scan this Code with your phone to join this meeting.
              </Typography>
            </div>
          )}
        </form>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};
