import {
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  withStyles,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useActiveSpeaker, useClient, useSpeakerQueue } from '../hooks';
import { QueueEntry } from '../types';
import QueueListEntry from './QueueListEntry';
import { SectionHeadline } from './SectionHeadline';

const Container = styled.div`
  align-items: center;
  padding-top: 8px;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 300px;
  text-align: center;
`;

const StyledListItem = withStyles({
  root: {
    borderRadius: 4,
  },
})(ListItem);

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MAX_QUEUE_USERS_TO_SHOW = 2;

const Queue: React.FunctionComponent = () => {
  const client = useClient();
  const [showWholeQueue, setShowWholeQueue] = useState(false);
  const queue = useSpeakerQueue();
  const queuePosition = queue.findIndex((qe) => qe.id === client?.userId) + 1;
  const activeSpeaker = useActiveSpeaker();

  const removeUserFromQueueFn = useCallback(
    (queueEntryId: QueueEntry['id']) => () => {
      client?.removeFromQueue(queueEntryId);
    },
    [client]
  );

  const moveUserToTopOfQueueFn = useCallback(
    (queueEntryId: QueueEntry['id']) => () => client?.pickFromQueue(queueEntryId),
    [client]
  );

  const users = [...queue] // we need to copy the array because "splice" doesn’t create one for us
    .splice(0, showWholeQueue ? queue.length : MAX_QUEUE_USERS_TO_SHOW)
    .map((queueEntry) => {
      return (
        <QueueListEntry
          key={queueEntry.id}
          data={queueEntry}
          onRemove={removeUserFromQueueFn(queueEntry.id)}
          onSelect={moveUserToTopOfQueueFn(queueEntry.id)}
        />
      );
    });

  let queueText = queuePosition
    ? `You're the ${queuePosition}. in the queue`
    : "You're not in the queue";

  if (queue.length === 0) {
    queueText = 'Queue is empty';
  }

  const showMoreText = showWholeQueue
    ? `Show less`
    : `Show ${queue.length - MAX_QUEUE_USERS_TO_SHOW} more...`;

  return (
    <Container>
      <SectionHeadline>{queueText}</SectionHeadline>
      {(activeSpeaker || users.length > 0) && (
        <List>
          {activeSpeaker && (
            <StyledListItem
              button={false as any}
              selected
              style={{ backgroundColor: 'rgba(24, 195, 27, 0.3)' }}
            >
              <ListItemText primary={activeSpeaker.name} />
              <ListItemSecondaryAction>
                <Button aria-label='delete' onClick={removeUserFromQueueFn(activeSpeaker.id)}>
                  <span aria-label='Remove ' role='img'>
                    ✅
                  </span>
                </Button>
              </ListItemSecondaryAction>
            </StyledListItem>
          )}
          {users}
        </List>
      )}
      <ButtonsWrapper>
        {queue.length > MAX_QUEUE_USERS_TO_SHOW ? (
          <Button size='small' onClick={() => setShowWholeQueue(!showWholeQueue)}>
            {showMoreText}
          </Button>
        ) : (
          <div />
        )}
        {queue.length > 0 && (
          <Button size='small' onClick={client?.clearSpeakerQueue}>
            Clear queue
          </Button>
        )}
      </ButtonsWrapper>
    </Container>
  );
};

export default Queue;
