import { v4 as uuidv4 } from "uuid";
import { Message, MeetingState } from "../types";

type MeetingStateListener = (state: MeetingState) => void

const EMCEE_CLIENT_ID_KEY = "EMCEE_CLIENT_ID"
const PING_MESSAGE = JSON.stringify({ action: "get", type: "ping" })
class Client {
    public userId: string
    public name: string
    public meeting?: string
    private listeners: Array<MeetingStateListener> = []
    private socket?: WebSocket
    private pingIntervalReference?: number;

    constructor(name: string) {
        // User Id
        const storedUserId = localStorage.getItem(EMCEE_CLIENT_ID_KEY)
        if (storedUserId) {
            this.userId = storedUserId
        } else {
            const newUserId = uuidv4()
            localStorage.setItem(EMCEE_CLIENT_ID_KEY, newUserId);
            this.userId = newUserId;
        }

        // Name
        this.name = name
    }

    connect = (meeting: string) => {
        return new Promise((resolve, reject) => {
            this.meeting = meeting.toLocaleLowerCase();
            const url = `wss://${process.env.REACT_APP_WS_SERVER}?username=${this.name}&userId=${this.userId}&meeting=${this.meeting}`
            this.socket = new WebSocket(url);
            this.socket.addEventListener("message", this.notifyMeetingStateListeners)
            this.socket.addEventListener("open", () => {
                console.log("connection established")
                this.pingIntervalReference = setInterval(this.ping, 5000);
                resolve(true)
            });
            this.socket.addEventListener("error", (error) => {
                console.log(`unable to establish connection to "${url}"`, { error })
                reject(error)
            });
            this.socket.addEventListener("close", (error) => {
                console.log(`connection closed"`, { error })
                reject(error)
            });
        })
    }

    private reconnect = async () => {
        if (this.meeting) {
            return this.connect(this.meeting)
        }
    }

    addMeetingStateListener = (listenerFn: MeetingStateListener) => {
        this.listeners.push(listenerFn)
        if (this.listeners.length === 1) {
            this.sendMessage({ action: "get" })
        }
    }

    private notifyMeetingStateListeners = (incomingMessage: any) => {
        if (this.listeners && Array.isArray(this.listeners)) {
            const newMeetingState = JSON.parse(incomingMessage.data)
            this.listeners.forEach(listener => listener(newMeetingState))
        }
    }

    private ping = () => {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(PING_MESSAGE)
        } else if (this.socket && (this.socket.readyState !== WebSocket.CONNECTING || this.socket.readyState !== WebSocket.CLOSING)) {
            this.reconnect();
        } else {
            console.log("waiting for socket to establish or close connection")
        }

    }

    sendMessage = (message: Message) => {
        console.log("sending message:", { message })
        this.socket?.send(JSON.stringify(message))
    }

    addVote = (payload: Message['payload']) => {
        this.sendMessage({ action: "add", type: "vote", payload })
    }

    removeVote = (payload: Message['payload']) => {
        this.sendMessage({ action: "remove", type: "vote", payload })
    }

    clearAllVotes = () => {
        this.sendMessage({ action: "clear", type: "vote" })
    }

    enqueue = (payload: Message['payload'] = "speaker") => {
        this.sendMessage({ action: "add", type: "queue", payload, userId: this.userId })
    }

    dequeue = (payload: Message['payload'] = "speaker") => {
        this.sendMessage({ action: "remove", type: "queue", payload, userId: this.userId })
    }

    clearQueue = (payload: Message['payload'] = "speaker") => {
        this.sendMessage({ action: "clear", type: "queue", payload })
    }
    clearSpeakerQueue = () => {
        this.clearQueue();
    }

    pickFromQueue = (userId: Message['userId'], payload: Message['payload'] = "speaker") => {
        this.sendMessage({ action: "pick", type: "queue", payload, userId })
    }

    removeFromQueue = (userId: Message['userId'], payload: Message['payload'] = "speaker") => {
        this.sendMessage({ action: "remove", type: "queue", payload, userId })
    }
}
export default Client;
