import { DEFAULT_VOTES } from '../components/DefaultVotes'


const palette = {
    teal: 'rgba(34,193,195,1)',
    yellow: 'rgba(253,187,45,1)',
    green: '#ADC865',
    blue: '#89D5C9',
    orange: '#FF8357',
    red: '#E25B45'
}

export default {
    ...palette
}

export const DefaultVotesColors = {
    [DEFAULT_VOTES[0]]: palette.orange,
    [DEFAULT_VOTES[1]]: palette.green,
    [DEFAULT_VOTES[2]]: palette.red,
    [DEFAULT_VOTES[3]]: palette.blue,
}

export const ColorsArray = Object.values(palette)
