import { Button, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import DefaultVotes from '../components/DefaultVotes';
import Enqueue from '../components/Enqueue';
import Queue from '../components/Queue';
import Vote from '../components/Vote';
import { useClient, useMeetingState } from '../hooks';
import { DefaultVotesColors } from '../theme/Colors';
import SecondaryActions from '../components/SecondaryActions';
import styled from 'styled-components';

const useStyles = makeStyles((theme) =>
  createStyles({
    votes: { flex: '1', display: 'flex', flexDirection: 'column', position: 'relative' },
    customVotes: {
      background: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '8px',
      paddingBottom: '16px',
    },
    enqueue: {
      background: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '8px',
    },
  })
);

const VoteActionContainer = styled.div<{ hidden?: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  transition: opacity ease-in-out 300ms;
`;

const StyledButton = styled(Button)`
  &&:not(:hover) {
    background-color: #fafafa3b;
  }
`;

export default () => {
  const classes = useStyles();
  const client = useClient();
  const { votes } = useMeetingState();
  //const votes = [{ count: 1, payload: '👍', userIds: ['aa'] }];
  const overallVotes = React.useMemo(
    () => (votes ? votes.reduce((prev, cur) => prev + cur.count, 0) : 1),
    [votes]
  );

  return (
    <>
      <div className={classes.votes}>
        {votes &&
          votes.map((vote) => (
            <Vote
              key={`${vote.count}_${vote.payload}`}
              data={vote}
              percentage={vote.count / overallVotes}
              color={DefaultVotesColors[vote.payload]}
            />
          ))}
        <VoteActionContainer hidden={votes?.length === 0}>
          <StyledButton onClick={votes?.length > 0 ? client?.clearAllVotes : () => {}}>
            🗑 Clear all votes
          </StyledButton>
        </VoteActionContainer>
      </div>

      <Queue />
      <div className={classes.enqueue}>
        <Enqueue />
      </div>
      <div className={classes.customVotes}>
        <DefaultVotes />
      </div>
      <SecondaryActions />
    </>
  );
};
