import { Button, withStyles } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { useClient, useMeetingState } from '../hooks';
import { SectionHeadline } from './SectionHeadline';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const StyledButton = withStyles({
  root: {
    margin: 8,
    fontSize: '1.75em',
  },
})(Button);

export const DEFAULT_VOTES = ['⏰', '👍', '👎', '👏'];

const DefaultVotes = () => {
  const client = useClient();

  const { votes } = useMeetingState();
  // const [myVote, updateMyVote] = useState<string | null>();
  const myVotes = client?.userId ? votes.filter(vote => vote.userIds.includes(client?.userId)) : [];

  const createUpdateVoteAction = (payload: string) => () => {
    if (myVotes.length > 0) {
      myVotes.forEach(vote => client?.removeVote(vote.payload));
    }
    client?.addVote(payload);
  };

  const removeVote = (payload: string) => () => {
    client?.removeVote(payload);
  };

  return (
    <Container>
      <SectionHeadline>Vote</SectionHeadline>
      <ButtonContainer>
        {DEFAULT_VOTES.map((payload, idx) => (
          <StyledButton
            key={`default_${idx}`}
            onClick={myVotes.some(v => v.payload === payload) ? removeVote(payload) : createUpdateVoteAction(payload)}
            variant='outlined'
            style={{
              opacity: myVotes.some(v => v.payload === payload) ? 0.3 : 1,
            }}
          >
            {payload}
          </StyledButton>
        ))}
      </ButtonContainer>
    </Container>
  );
};

export default DefaultVotes;
