import { useEffect, useState } from 'react';
import { Key } from 'ts-keycode-enum';

interface KeypressOptions {
  onKeydown?: () => void;
  onKeyup?: () => void;
}

export { Key };

export function useKeyPress(targetKeyCode: Key, options: KeypressOptions): boolean {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    let prevKeyCode: string | number;

    function downHandler({ keyCode }: KeyboardEvent) {
      if (prevKeyCode === targetKeyCode) return;

      if (keyCode === targetKeyCode) {
        setKeyPressed(true);
        options?.onKeydown && options.onKeydown();
        prevKeyCode = keyCode;
      }
    }

    function upHandler({ keyCode }: KeyboardEvent) {
      if (keyCode === targetKeyCode) {
        setKeyPressed(false);
        options?.onKeyup && options.onKeyup();
        prevKeyCode = '';
      }
    }

    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [options, targetKeyCode]);

  return keyPressed;
}
