import { CssBaseline } from '@material-ui/core';
import { createMuiTheme, ThemeProvider as ThemeProviderBase } from '@material-ui/core/styles';
import React from 'react';
import Colors from './Colors';

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: 'light',
          primary: { main: Colors.teal, contrastText: '#ffffff' },
        },
      }),
    []
  );

  return (
    <ThemeProviderBase theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProviderBase>
  );
}
