import {
  Button,
  createStyles,
  makeStyles,
  TextField,
  Theme,
  Typography,
  Grid,
  Box,
  Container,
} from '@material-ui/core';
import { styled, withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { useNavigator, useSetClient } from '../hooks';
import Colors from '../theme/Colors';
import Client from '../client';
import { EMCEE_LAST_MEETING_KEY, EMCEE_LAST_USERNAME_KEY } from './Join';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      minHeight: '100%',
      backgroundColor: 'rgb(247, 247, 247)',
      padding: `${theme.spacing(4)}px 0`,

      [theme.breakpoints.down('sm')]: {
        minHeight: 'inherit',
      },
    },
    screenshot: {
      width: '100%',
      borderRadius: 8,
      boxShadow: '0 0 20px 0px #00000036',
    },
    screenshotWrapper: {
      justifyContent: 'center',
      maxHeight: '80%',
      maxWidth: 500,
      overflow: 'hidden',
      padding: theme.spacing(8),
    },
    form: {
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        flexDirection: 'column',
      },
      marginBottom: theme.spacing(2),
      '& > *': {
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
      },
    },
  })
);

const Logo = styled(Box)(({ theme }) => ({
  width: 100,
  '& img': {
    width: '100%',
  },

  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(4),
  },
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(8),
  },
}));

const MaxWidthContainer = styled(Container)(({ theme }) => ({
  position: 'relative',
}));

const StyledGridItem = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
  },
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
  },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    marginBottom: 0,
    alignItems: 'center',
  },
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(20),
    alignItems: 'flex-start',
  },
}));

const ContentTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.3rem',
    lineHeight: '2.1rem',
    textAlign: 'center',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '3.3rem',
    lineHeight: '3.1rem',
  },
  fontWeight: 700,
}));

const ContentSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.1rem',
  fontWeight: 300,
  marginTop: theme.spacing(2),
}));

const FormTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.1rem',
  fontWeight: 300,
  marginTop: theme.spacing(4),
}));

const StyledInput = withStyles({
  root: {
    '& input:valid:focus + fieldset': {
      borderColor: Colors.teal,
    },
    '& label.Mui-focused': {
      color: Colors.teal,
    },
  },
})(TextField);

const ContactWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  '& a': {
    fontSize: '1.2em',
  },
  '& svg': {
    marginRight: theme.spacing(0.5),
  },
}));

const Footer = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  position: 'absolute',
  bottom: -theme.spacing(8),
  left: 0,
  right: 0,
  paddingBottom: theme.spacing(2),

  '& a': {
    //fontSize: '0.8em',
    textDecoration: 'none',
    color: 'rgb(80 80 80)',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const LandingPage = () => {
  const classes = useStyles();
  const navigator = useNavigator();
  const setClient = useSetClient();
  const [name, setName] = useState('');
  const [meetingName, setMeetingName] = useState('');

  const joinMeeting = React.useCallback(async () => {
    console.log('Join meeting...');
    //setLoading(true);
    localStorage.setItem(EMCEE_LAST_USERNAME_KEY, name);
    localStorage.setItem(EMCEE_LAST_MEETING_KEY, meetingName);
    const client = new Client(name);
    await client.connect(meetingName);
    setClient(client);
    setTimeout(() => navigator.navigate('PARTICIPANT'), 2000);
    window.history.pushState({ meetingName }, meetingName, `/${meetingName}`);
  }, [setClient, meetingName, name, navigator]);

  return (
    <div className={classes.container}>
      <MaxWidthContainer maxWidth={'md'}>
        <Grid container justify='center' alignItems='center' spacing={2}>
          <StyledGridItem item sm={12} md={6} container>
            <Logo>
              <img src='./images/finger.png' alt='emcee logo' />
            </Logo>
            <ContentBox>
              <ContentTitle>Mit emcee kommt jeder zu Wort!</ContentTitle>
              <ContentSubtitle>Videokonferenzen interaktiver gestalten.</ContentSubtitle>
              <FormTitle>Erstelle ein emcee für dein Meeting oder tritt einem bei:</FormTitle>
              <form noValidate autoComplete='off' className={classes.form}>
                <StyledInput
                  id='outlined-basic'
                  label='Meeting Name'
                  variant='outlined'
                  color='primary'
                  onChange={(e) => setMeetingName(e.target.value)}
                  value={meetingName}
                />
                <StyledInput
                  id='outlined-basic'
                  label='Dein Name'
                  variant='outlined'
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </form>
              <Button
                variant='contained'
                color='primary'
                disabled={!name || !meetingName}
                onClick={joinMeeting}
              >
                Start
              </Button>
            </ContentBox>
          </StyledGridItem>
          <StyledGridItem item sm={12} md={6} justify='flex-start' alignItems='center' container>
            <div className={classes.screenshotWrapper}>
              <img
                src='./images/browser-screenshot.png'
                alt='emcee screenshot'
                className={classes.screenshot}
              />
            </div>
          </StyledGridItem>
        </Grid>
        <Footer>
          <ContactWrapper>
            <MailOutlineIcon />
            <a href='mailto:hi@emcee.live?subject=Feedback:'>hi@emcee.live</a>
          </ContactWrapper>
          <a href='https://elbstack.com/de/impressum/'>Impressum</a> |{' '}
          <a href='https://elbstack.com/de/datenschutz/'>Datenschutz</a>
        </Footer>
      </MaxWidthContainer>
    </div>
  );
};

export default LandingPage;
