import { Button } from '@material-ui/core';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Key, useActiveSpeaker, useClient, useKeyPress, useSpeakerQueue } from '../hooks';
import { SectionHeadline } from './SectionHeadline';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Enqueue: React.FunctionComponent = () => {
  const client = useClient();
  const queue = useSpeakerQueue();
  const activeSpeaker = useActiveSpeaker();
  const inQueue = React.useMemo(() => !!queue.find((qe) => qe.id === client?.userId), [client, queue]);
  const isDisabled = React.useMemo(() => activeSpeaker?.id === client?.userId, [activeSpeaker, client]);
  const handleQueue = useCallback(() => (inQueue ? client?.dequeue() : client?.enqueue()), [client, inQueue]);
  useKeyPress(Key.Space, { onKeydown: handleQueue });

  return (
    <Container>
      <SectionHeadline>Enqueue</SectionHeadline>
      <Button
        onClick={handleQueue}
        variant='outlined'
        disabled={isDisabled}
        style={{
          fontSize: 28,
          margin: '0 8px',
          opacity: inQueue ? 0.3 : 1,
        }}
      >
        <span aria-label='Raise Hand' role='img'>
          ☝️
        </span>
      </Button>
    </Container>
  );
};

export default Enqueue;
