import NoSleep from 'nosleep.js';
import React, { useContext } from 'react';
import { ClientContext, MeetingStateContext, NavigatorContext } from '../App';
import { MeetingState, QueueEntry } from '../types';

export { Key, useKeyPress } from './useKeyPress';

export const useNavigator = () => useContext(NavigatorContext);
export const useClient = () => useContext(ClientContext).client;
export const useSetClient = () => useContext(ClientContext).setClient;

export const useMeetingState = (): MeetingState => useContext(MeetingStateContext);

export const useSpeakerQueue = (): Array<QueueEntry> => {
  const { queues } = useMeetingState();
  if (queues.length) {
    const speakerQueue = queues.find((q) => q.payload === 'speaker');
    return speakerQueue?.queue || [];
  }
  return [];
};

export const useActiveSpeaker = (): QueueEntry | null => {
  const { queues } = useMeetingState();

  if (queues.length) {
    const speakerQueue = queues.find((q) => q.payload === 'speaker');
    return speakerQueue?.head || null;
  }
  return null;
};

export function useNoSleep() {
  const noSleep = React.useRef<NoSleep>();
  const [active, setActive] = React.useState(false);

  React.useEffect(() => {
    noSleep.current = new NoSleep();
    return () => {
      noSleep.current?.disable();
      noSleep.current = undefined;
    };
  }, []);
  const toggle = React.useCallback(() => {
    switch (active) {
      case true:
        noSleep.current?.disable();
        break;
      case false:
        noSleep.current?.enable();
    }
    setActive((currentState) => !currentState);
  }, [active]);

  return [active, toggle] as const;
}
