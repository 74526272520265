import {
  Button,
  Divider,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  withStyles
} from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { QueueEntry } from "../types";


const StyledListItem = withStyles({
  root: {
    borderRadius: 4,
  },
})(ListItem);



interface QueueListEntryProps {
  data: QueueEntry;
  onRemove:()=>void;
  onSelect:()=>void;
}
const QueueListEntry: React.FC<QueueListEntryProps> = ({onSelect,data, onRemove}) => {
  const [isRemovedLocked, setRemoveLock] = useState(true);

  const onRemoveWithLock = useCallback(
    () => {
      if(isRemovedLocked){
        setRemoveLock(false)
        setTimeout(()=>setRemoveLock(true), 3000);
      }else{
        onRemove()
      }
    },
    [isRemovedLocked, onRemove]
  );

  return (
    <div>
      <StyledListItem button={false as any}>
        <ListItemText primary={data.name} />
        <ListItemSecondaryAction>
          <Button
            aria-label="speak"
            onClick={onSelect}
          >
            <span aria-label="Pick" role="img">
              🔝
            </span>
          </Button>
          <Button
            aria-label="delete"
            onClick={onRemoveWithLock}
          >
            <span aria-label="Remove " role="img">
              {isRemovedLocked ? '🗑' : '❌' }
            </span>
          </Button>
        </ListItemSecondaryAction>
      </StyledListItem>
      <Divider component="li" />
    </div>
  );

};

export default QueueListEntry;
