import React, { useMemo } from "react";
import { Vote as VoteType } from "../../types";
// import VoteButton from "./VoteButton";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

const Container = styled.div<{ percentage: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: ${(props) => props.percentage};
  * > {
    padding: 16px;
  }
  background-color: ${(props) => props.color};
  transition: all 0.5s ease-out;
`;

const VoteLabel = styled(animated.div)`
  font-size: 28px;
  color: white;
  font-weight: bold;
`;

const Vote: React.FunctionComponent<{
  data: VoteType;
  percentage: number;
  color: string;
}> = ({ data, percentage, color }) => {
  const fontSize = useMemo(() => 34 * (1 + percentage), [percentage]);
  const props = useSpring({
    fontSize: fontSize,
    from: { fontSize: fontSize * 2 },
  });
  return (
    <Container percentage={percentage} color={color}>
      <VoteLabel style={props}>{`${data.payload} (${data.count})`}</VoteLabel>
    </Container>
  );
};
export default Vote;
