import { createStyles, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    rootSectionHeadline: {
      color: theme.palette.text.secondary,
      margin: theme.spacing(1, 0),
      textAlign: 'center',
    },
  })
);

export function SectionHeadline({ children }: { children: React.ReactNode }) {
  const classes = useStyles();
  return <Typography variant="caption" classes={{ root: classes.rootSectionHeadline }}>{children}</Typography>;
}
